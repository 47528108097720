export default function PageNotFound() {
  return (
    <div className='text-center mx-auto h-screen w-full'>
      <div className='h-full md:mx-auto px-4 flex flex-col justify-center items-center'>
        <div className='text-lg md:text-2xl pb-3'>
          The page you are looking for has been moved or does not exist.
        </div>
        <a
          href='/'
          style={{ color: '#075fc9' }}
          className='text-sm md:text-lg'
        >
          Go back to Homepage
        </a>
      </div>
    </div>
  );
}
